import { useState, useEffect } from 'react';
import { css } from "@emotion/react"
import { StaticImage } from "gatsby-plugin-image"
import { theme } from "../../constants/theme"
import getAppLink from '../../utils/app-link'
import Margin from "../margin"
import AppQRCode from './app-qrcode';

import IntroManImage from "../../images/intro-man.inline.svg";

const WebCTA = () =>
  <div
    onClick={() => (window.location.href = "https://app.thoth.tw")}
    css={css`
      cursor: pointer;
      padding: 6px;
      background: black;
      color: white;
      font-weight: bold;
      font-size: 16px;
      line-height: 28px;
      text-align: center;
      border-radius: 4px;
      width: 140px;
      box-sizing: border-box;
      @media ${theme.devices.max.mobile} {
        width: 100%;
      }
    `}
  >
    網頁版
  </div>

const AppCTA = () => {
  const [appLink, setAppLink] = useState(null);

  useEffect(() => {
    setAppLink(getAppLink());
  });

  const style = css`
    cursor: pointer;
    padding: 5px;
    border: 1px solid black;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    border-radius: 4px;
    width: 140px;
    box-sizing: border-box;
    @media ${theme.devices.max.mobile} {
      width: 100%;
    }
  `;

  if (appLink) {
    return (
      <a css={style} href={appLink} target="_blank" rel="noreferrer">使用 APP</a>
    )
  }

  return (
    <AppQRCode position={['top']}>
      <div css={style}>
        使用 APP
      </div>
    </AppQRCode>
  )
}

export default function AppSection() {
  return (
    <Margin removePaddingForMobile={true}>
      <div css={css`
        padding-bottom: 40px;
        @media ${theme.devices.max.mobile} {
          background: ${theme.colors.primary50};
          padding-bottom: 0;
        }
      `}>
        <div
          css={css`
            position: relative;
            display: flex;
            align-items: center;
            margin-top: -50px;
            padding: 50px 15px 0;
            background: ${theme.colors.rating100};
            border-radius: 4px 200px 4px 4px;
            @media ${theme.devices.max.laptop} {
              flex-direction: column;
            }
          `}
        >
          <StaticImage
            css={css`
              max-width: 650px;
              margin-top: -120px;
            `}
            src="../../images/app-intro.png"
            alt="app image"
            placeholder="tracedSVG"
          />
          <IntroManImage
            css={css`
              width: 300px;
              position: absolute;
              top: -85px;
              left: 50%;
              @media ${theme.devices.max.laptop} {
                display: none;
              }
            `}
          />
          <div
            css={css`
              margin: auto;
              padding-right: 100px;
              padding-bottom: 50px;
              max-width: 300px;
              @media ${theme.devices.max.laptopL} {
                margin-top: 40px;
              }
              @media ${theme.devices.max.laptop} {
                margin-top: 0;
                max-width: 500px;
                padding-right: 0;
              }
              @media ${theme.devices.max.mobile} {
                text-align: center;
              }
            `}
          >
            <div css={css`font-weight: bold; font-size:20px;`}>沒有你想像的那麼難</div>
            <div
              css={css`
                padding-top: 10px
              `}
            >
              在托特你可以瀏覽各式正在找技能交換、學生、家教、讀書會的貼文，或自行輕鬆的發佈需求。
      我們的宗旨就是想讓人與人能夠簡單方便地進行知識交流，現在就跨出第一步吧：）
            </div>
            <div
              css={css`
                display: flex;
                margin-top: 35px;
                & > * + * {
                  margin-left: 15px;
                }
                @media ${theme.devices.max.mobile} {
                  flex-direction: column-reverse;
                  & > * + * {
                    margin-left: 0;
                    margin-bottom: 20px;
                  }
                }
              `}
            >
              <AppCTA />
              <WebCTA />
            </div>
          </div>
        </div>
      </div>
    </Margin>
  )
}
