import { css } from "@emotion/react"
import { Carousel } from 'react-responsive-carousel';
import { theme } from "../../constants/theme"

import Quote from "../../images/quote.inline.svg"
import testimonial1Img from "../../images/testimonial1.png"
import testimonial2Img from "../../images/testimonial2.jpg"
import testimonial3Img from "../../images/testimonial3.jpg"
import 'react-responsive-carousel/lib/styles/carousel.min.css';


const Section = ({ title, content, sign, image }) =>
  <section
    css={css`
      padding: 35px 0;
      @media ${theme.devices.max.mobile} {
        padding: 25px 0;
      }
    `}
  >
    <div css={css`font-weight: bold; font-size:18px; margin-bottom: 15px`}>{title}</div>
    <div css={css`margin-bottom: 30px;`}>{content}</div>
    <div css={css`text-align: center;`}>
      <img
        css={css`
          border-radius: 50%;
          object-fit: cover;
          width: 40px !important;
          height: 40px;
        `}
        src={image}
        />
      <div
        css={css`
          margin-top: 5px;
          margin-bottom: 20px;
          font-weight: 500;
        `}
        >
        {sign}
      </div>
    </div>
  </section>

const Case1 = () =>
  <Section
    title="「成功透過thoth交換履歷修改，並學到一直想嘗試的塔羅牌。」"
    content="使用托特是因為朋友推薦，上面有很多交換技能，像是寫程式、英日語教學等等。抱著試試看的心態下載，發現平台簡單易操作、也從沒有當機問題，上面尋求技能交換的人都很認真，比起FB社團常常碰到亂傳騷擾訊息的人，我在thoth上從來沒遇過。使用3-4個月，我成功透過touth交換出履歷修改，並學到一直很想嘗試的塔羅牌。"
    sign="Yvonne, 2021.09"
    image={testimonial1Img}
  />

const Case2 = () =>
  <Section
    title="「大約半年的現在我已經可以獨立寫動態網頁了。」"
    content="我是純商背景，在2020二月初決定要開始學寫程式，但一開始什麼都不會也沒錢去上課，身邊也沒有認識的工程師，蠻沒有方向的。一次滑臉書時看到托特，抱著姑且一試的心態來交換技能，沒想到才剛Po，馬上就有真的很優秀的工程師願意跟我交換技能，大約半年的現在我已經可以獨立寫出動態網頁了。 很謝謝在thoth上遇到的優秀前輩們，推薦thoth給你！"
    sign="Rosy Lo, 2020.07"
    image={testimonial2Img}
  />

const Case3 = () =>
  <Section
    title="「用數位行銷交換PS繪畫，也交到幾位網友，近期在規劃創業。」"
    content="我在App上用數位行銷的交換到PS繪畫技術，也交到幾位志同道合願意一起成長的網友，近期還可以規劃創業，運用彼此的技能整合出一個商業模式兜售。雖然跟你交換的人可能不夠專業，但至少比甚麼都不懂上網爬文沒有系統性的學習好多了，更不用說人與人之間培養出的信任和感情，花錢去學當然比較好，但就不會有種革命患難的情感了。"
    sign="益軒, 2020.10"
    image={testimonial3Img}
  />

const CarouselIndicator = ({ onClickHandler, isSelected, index }) =>
  (isSelected) ?
    <li
      css={css`
        display: inline-block;
        height: 4px;
        width: 25%;
        background: ${theme.colors.rating500};
        margin: 0 5px;
      `}
    /> :
    <li
      css={css`
        cursor: pointer;
        display: inline-block;
        height: 4px;
        width: 25%;
        background: ${theme.colors.primary200};
        margin: 0 5px;
      `}
      onClick={onClickHandler}
      value={index}
      key={index}
    />

const CaseCarousel = () =>
  <Carousel
    showArrows={false}
    showStatus={false}
    showThumbs={false}
    infiniteLoop={true}
    autoPlay={true}
    interval={10000}
    centerMode={false}
    renderIndicator={(onClickHandler, isSelected, index) =>
      <CarouselIndicator
        index={index}
        isSelected={isSelected}
        onClickHandler={onClickHandler}
      />
    }
  >
    <Case1 />
    <Case2 />
    <Case3 />
  </Carousel>

export default function Testimonial() {
  return (
    <div
      css={css`
        position: relative;
        padding: 40px 15px;
        background: ${theme.colors.primary50};
        @media ${theme.devices.max.mobile} {
          padding: 5px 15px 0;
        }
      `}
    >
      <div
        css={css`
          position: absolute;
          top: -20px;
          left: 0;
          right: 0;
          width: 100%;
          text-align: center;
          font-size: 26px;
          font-weight: bold;
          @media ${theme.devices.max.mobile} {
            top: -35px;
            font-size: 20px;
          }
        `}
      >
        用戶的好評回饋 我們的前進動力
      </div>
      <Quote
        css={css`
          width: 60px;
          left: -10px;
          position: absolute;
          @media ${theme.devices.max.tablet} {
            width: 50px;
            left: 0;
          }
          @media ${theme.devices.max.mobile} {
            width: 40px;
          }
        `}
      />
      <div
        css={css`
          margin-top: 50px;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-column-gap: 95px;
          @media ${theme.devices.max.laptop} {
            display: none;
          }
        `}
      >
        <Case1 />
        <Case2 />
        <Case3 />
      </div>
      <div
        css={css`
          max-width: 580px;
          margin: 50px auto 0;
          display: none;
          & .carousel .slide {
            text-align: left;
          }
          @media ${theme.devices.max.laptop} {
            display: block;
          }
          @media ${theme.devices.max.mobile} {
            margin: 30px auto 0;
          }
        `}
      >
        <CaseCarousel />
      </div>
    </div>
  )
}
