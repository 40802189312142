import { css } from "@emotion/react"

import SEO from "../components/seo"
import Header from "../components/landing-page/header"
import HeroSection from "../components/landing-page/hero-section"
import FeatureSection from "../components/landing-page/feature-section"
import TestimonialSection from "../components/landing-page/testimonial-section"
import AppSection from "../components/landing-page/app-section"
import Footer from "../components/footer"
import Margin from "../components/margin"
import { theme } from "../constants/theme"

const IndexPage = () =>
  <>
    <SEO title="托特｜尋找技能交換夥伴，躋身新一代斜槓青年" />
    <Margin>
      <Header />
    </Margin>
    <Margin removePaddingForMobile={true}>
      <div
        css={css`
          position: relative;
          color: ${theme.colors.primary700};
        `}
      >
        <HeroSection />
      </div>
    </Margin>
    <Margin>
      <FeatureSection />
    </Margin>
    <div
      css={css`
        margin-top: 40px;
        background: ${theme.colors.primary50};
        padding-bottom: 100px;
      `}
    >
      <Margin>
        <TestimonialSection />
      </Margin>
    </div>
    <AppSection />
    <Footer />
  </>

export default IndexPage
