export default function getAppLink() {
  if (typeof navigator !== "undefined" && typeof window !== "undefined") {
    if (navigator.userAgent.match(/Android/i)) {
      return "https://play.google.com/store/apps/details?id=com.thothtw.thoth"
    } else if (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i)
    ) {
      return "https://apps.apple.com/tw/app/id1510380151"
    }
  }
  return null;
}