import { useState } from "react"
import { Popover, ArrowContainer } from "react-tiny-popover"
import { css } from "@emotion/react"

import qrcode from "../../images/qrcode.png"

export default function AppQRCode({ position, cssOverride, children }) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  // "window" is not available during server side rendering.
  if (typeof window === "undefined") {
    return (
      <div css={css`${cssOverride}`}>
        {children}
      </div>
    )
  }

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={position}
      padding={10}
      onClickOutside={() => setIsPopoverOpen(false)}
      content={({ position, childRect, popoverRect }) => (
        <div
          css={css`
            filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2));
          `}
        >
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor={'white'}
            arrowSize={12}
            className='popover-arrow-container'
            arrowClassName='popover-arrow'
          >
            <div
              css={css`
                width: 120px;
                height: 120px;
                padding: 8px;
                background: white;
              `}
              onClick={() => setIsPopoverOpen(!isPopoverOpen)}
            >
              <img
                src={qrcode}
                alt="qrcode"
              />
            </div>
          </ArrowContainer>
        </div>
      )}
    >
      <div css={css`${cssOverride}`} onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
        {children}
      </div>
    </Popover>
  )
}