import { Link } from "gatsby"
import { css } from "@emotion/react"
import getAppLink from '../../utils/app-link'
import AppQRCode from './app-qrcode';
import Logo from "../../images/logo.inline.svg"

import { theme } from "../../constants/theme"

const appLink = getAppLink();

export default function Header() {
  const appBtnStyle = css`
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    &:hover {
      color: ${theme.colors.primary};
    }
    @media ${theme.devices.max.mobile} {
      font-size: 16px;
    }
    @media ${theme.devices.max.mobilex} {
      font-size: 14px;
    }
  `;

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        padding: 25px 0;
        @media ${theme.devices.max.mobile} {
          padding: 10px 0;
        }
      `}
    >
      <Logo
        css={css`
          height: 30px;
          width: 30px;
          border-radius: 4px;
          margin-right: 15px;
          @media ${theme.devices.max.mobile} {
            height: 25px;
            width: 25px;
            margin-right: 10px;
          }
        `}
      />
      <div
        css={css`
          font-size: 20px;
          font-weight: 500;
          @media ${theme.devices.max.mobile} {
            font-size: 18px;
          }
        `}
      >
        托特 thoth
      </div>
      <Link
        css={css`
          font-size: 18px;
          font-weight: 500;
          margin-left: auto;
          margin-right: 40px;
          &:hover {
            color: ${theme.colors.primary};
          }
          @media ${theme.devices.max.mobile} {
            font-size: 16px;
            margin-right: 15px;
          }
          @media ${theme.devices.max.mobilex} {
            font-size: 14px;
            margin-right: 10px;
          }
        `}
        to={"/blog"}
      >
        部落格
      </Link>
      {appLink ?
        <a css={appBtnStyle} href={appLink} target="_blank" rel="noreferrer">使用 APP</a> :
        <AppQRCode position={['bottom']}><div css={appBtnStyle}>使用 APP</div></AppQRCode>
      }
    </div>
  )
}
