import { css } from "@emotion/react"
import { theme } from "../constants/theme"

export default function Margin({ children, removePaddingForMobile = false }) {
  return (
    <div
      css={css`
        position: relative;
        margin: 0 auto;
        width: 100%;
        max-width: ${theme.size.laptopXL};
        color: ${theme.colors.primary700};
      `}
    >
      <div
        css={css`
          position: relative;
          padding: 0 40px;
          @media ${theme.devices.max.mobile} {
            padding: ${removePaddingForMobile ? 0 : '20px'};
          }
        `}
      >
        {children}
      </div>
    </div>
  );
}