const size = {
  mobilex: "320px",
  mobile: "550px",
  tablet: "786px",
  laptop: "992px",
  laptopL: "1152px",
  laptopXL: "1280px",
  desktop: "1440px",
}

export const theme = {
  size: size,
  devices: {
    min: {
      mobilex: `(min-width: ${size.mobilex})`,
      mobile: `(min-width: ${size.mobile})`,
      tablet: `(min-width: ${size.tablet})`,
      laptop: `(min-width: ${size.laptop})`,
      laptopL: `(min-width: ${size.laptopL})`,
      laptopXL: `(min-width: ${size.laptopXL})`,
      desktop: `(min-width: '${size.desktop})`,
    },
    max: {
      mobilex: `(max-width: ${size.mobilex})`,
      mobile: `(max-width: ${size.mobile})`,
      tablet: `(max-width: ${size.tablet})`,
      laptop: `(max-width: ${size.laptop})`,
      laptopL: `(max-width: ${size.laptopL})`,
      laptopXL: `(max-width: ${size.laptopXL})`,
      desktop: `(max-width: '${size.desktop})`,
    },
  },
  colors: {
    backgroound: "#FCFCFC",

    primary: "#000000",
    primary700: "#333333",
    primary600: "#545454",
    primary500: "#757575",
    primary400: "#AFAFAF",
    primary300: "#CBCBCB",
    primary200: "#E2E2E2",
    primary100: "#EEEEEE",
    primary50: "#F6F6F6",

    accent: "#276EF1",
    accent700: "#102C60",
    accent600: "#174291",
    accent500: "#1E54B7",
    accent400: "#276EF1",
    accent300: "#5B91F4",
    accent200: "#A0BFF9",
    accent100: "#D4E2FC",
    accent50: "#EEF3FE",

    negative: "#D44333",
    negative700: "#5C1D16",
    negative600: "#892C21",
    negative500: "#AE372A",
    negative400: "#D44333",
    negative300: "#EB7567",
    negative200: "#F4AFA7",
    negative100: "#FADBD7",
    negative50: "#FDF0EF",

    warning: "#ED6E33",
    warning700: "#5F2C14",
    warning600: "#8E421F",
    warning500: "#B45427",
    warning400: "#ED6E33",
    warning300: "#F19164",
    warning200: "#F7BFA5",
    warning100: "#FBE2D6",
    warning50: "#FEF3EF",

    positive: "#3AA76D",
    positive700: "#1C472F",
    positive600: "#2B6B46",
    positive500: "#368759",
    positive400: "#3AA76D",
    positive300: "#73C496",
    positive200: "#AEDDC2",
    positive100: "#DAF0E3",
    positive50: "#F0F9F4",

    rating100: "#FFFAED",
    rating200: "#FFE3AC",
    rating400: "#FFC043",
    rating500: "#FADC65"
  },
}
