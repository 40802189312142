import { css } from "@emotion/react"
import { theme } from "../../constants/theme"

import FeatureImage1 from "../../images/feature1.inline.svg";
import FeatureImage2 from "../../images/feature2.inline.svg";
import FeatureImage3 from "../../images/feature3.inline.svg";

const Section = ({ imageComponent, title, content }) =>
  <div
    css={css`
      text-align: center;
      padding: 10px 0;
      @media ${theme.devices.max.tablet} {
        max-width: 400px;
        place-self: center;
        grid-column: 1/-1;
      }
      @media ${theme.devices.max.mobile} {
        max-width: 250px;
        place-self: center;
        grid-column: 1/-1;
      }
    `}
  >
    {imageComponent}
    <div
      css={css`
        font-weight: bold;
        font-size: 20px;
        text-decoration: underline;
        text-decoration-color: ${theme.colors.rating500};
        text-decoration-thickness: 3px;
      `}
    >
      {title}
    </div>
    <p
      css={css`
        color: ${theme.colors.primary600};
        font-size: 16px;
        margin: 30px auto 0;
        max-width: 320px;
      `}
    >
      {content}
    </p>
  </div>


export default function Feature() {
  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 80px;
        margin-bottom: 80px;
        @media ${theme.devices.max.laptop} {
          margin-top: 50px;
          margin-bottom: 80px;
        }
        @media ${theme.devices.max.tablet} {
          margin-top: 0;
        }
        @media ${theme.devices.max.mobile} {
          margin-bottom: 0;
        }
      `}
    >
      <Section
        imageComponent={<FeatureImage1 css={css`max-width: 100%;width:200px;height:200px`} />}
        title="學習之路孤獨坎坷？"
        content="練就新技能必定會遇到重重關卡，你是否也曾在學習的道路上遭遇阻礙？"
      />
      <Section
        imageComponent={<FeatureImage2 css={css`max-width: 100%;width:200px;height:200px`} />}
        title="讓夥伴來幫助你"
        content="與其獨自埋首苦讀 ，不如讓夥伴助你一臂之力。快來認識不同領域的夥伴！"
      />
      <Section
        imageComponent={<FeatureImage3 css={css`max-width: 100%;width:200px;height:200px`} />}
        title="到托特找適合你的"
        content="現在就到托特找技能交換、讀書會，學生、家教，最棒的是尋找和刊登都完全免費。"
      />
    </div>
  )
}