import { css } from "@emotion/react"
import { theme } from "../../constants/theme"

import HeroImg from '../../images/hero.inline.svg'

const CTA = () =>
  <div
    onClick={() => (window.location.href = "https://app.thoth.tw")}
    css={css`
      cursor: pointer;
      margin-top: 40px;
      padding: 12px;
      background: black;
      color: white;
      font-weight: bold;
      font-size: 20px;
      width: 360px;
      text-align: center;
      border-radius: 4px;
      box-sizing: border-box;
      @media ${theme.devices.max.mobile} {
        width: 100%;
      }
    `}
  >
    尋找學習夥伴
  </div>


export default function Hero() {
  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-row-gap: 20px;
        @media ${theme.devices.max.laptop} {
          grid-template-columns: repeat(8, 1fr);
        }
    `}
    >
      <div
        css={css`
          grid-column: span 4;
          display: flex;
          flex-direction: column;
          justify-content: center;
          @media ${theme.devices.max.laptopL} {
            grid-column: span 5;
          }
          @media ${theme.devices.max.laptop} {
            text-align: center;
            padding: 0 40px;
            align-items: center;
            grid-column: 1 / -1;
            grid-row: 2;
          }
          @media ${theme.devices.max.mobile} {
            padding: 0 30px;
            text-align: left;
            align-items: start;
            margin: auto;
          }
          @media ${theme.devices.max.mobilex} {
            padding: 0 20px;
          }
        `}
      >
        <div
          css={css`
            font-size: 20px;
            font-weight: bold;
            color: ${theme.colors.primary700};
            padding-left: 15px;
            border-left: 4px solid ${theme.colors.primary700};
            @media ${theme.devices.max.mobile} {
              font-size: 18px;
            }
          `}
        >
          技能交換、讀書會、家教
        </div>
        <h1 css={css`
          margin-top: 20px;
          margin-bottom: 10px;
          @media ${theme.devices.max.mobile} {
            font-size: 24px;
          }
          @media ${theme.devices.max.mobilex} {
            font-size: 22px;
          }
        `}>
          知識夥伴一起 啟動斜槓人生
        </h1>
        <div
          css={css`
            max-width: 360px;
            margin-top: 15px;
            font-size: 18px;
            color: ${theme.colors.primary600};
            @media ${theme.devices.max.laptop} {
              max-width: inherit;
            }
            @media ${theme.devices.max.tablet} {
              max-width: 360px;
            }
            @media ${theme.devices.max.mobile} {
              font-size: 16px;
            }
          `}
        >
          托特是一個匯集了語言、資訊、音樂等各種知識的技能交換平台
        </div>
        <CTA />
      </div>
      <div
        css={css`
          grid-column: span 8;
          @media ${theme.devices.max.laptopL} {
            grid-column: span 7;
          }
          @media ${theme.devices.max.laptop} {
            max-width: 550px;
            width: 100%;
            place-self: center;
            grid-column: 1/-1;
            grid-row: 1;
          }
        `}
      >
        <HeroImg />
      </div>
    </div>
  )
}
