import { css } from "@emotion/react"
import { Link } from "gatsby"

import { theme } from "../constants/theme"
import Margin from "../components/margin"
import appStore from "../images/app-store.svg"
import googlePlay from "../images/google-play.png"

const AppButton = ({ link, image }) =>
  <a
    css={css`
      border: none;
    `}
    href={link}
    target="_blank"
    rel="noreferrer"
  >
    <img
      css={css`height: 35px;`}
      src={image}
    />
  </a>

const Bottom = () =>
  <div
    css={css`
      display: flex;
      margin-top: 15px;
      color: ${theme.colors.primary400};
      & > * {
        color: ${theme.colors.primary50};
        margin-right: 20px;
        font-size: 16px;
      }
    `}
  >
    <Link to={"/policy"}>隱私權政策</Link>
    <div>© {new Date().getFullYear()} 托特 thoth</div>
  </div>

export default function Footer() {
  return (
    <footer css={css`
      display: flex;
      background: #101010;
    `}>
      <Margin>
        <div
          css={css`
            display: flex;
            padding: 40px 0;
            align-items: center;
            @media ${theme.devices.max.tablet} {
              flex-direction: column;
              align-items: flex-start;
            }
          `}
        >
          <div>
            <div
              css={css`
                display: flex;
                flex-wrap: wrap;
                color: ${theme.colors.primary200};
                & > * {
                  margin-right: 20px;
                  margin-bottom: 10px;
                  color: ${theme.colors.primary50};
                  font-size: 16px;
                }
                @media ${theme.devices.max.tablet} {
                  flex-direction: column;
                  margin-bottom: 20px;
                }
              `}
            >
              <div>聯絡我們 support@thoth.tw</div>
              <Link to={"/blog"}>托特部落格</Link>
              <a href="https://www.facebook.com/thothtw" target="_blank" rel="noreferrer">Facebook</a>
              <a href="https://www.instagram.com/hi.thoooth" target="_blank" rel="noreferrer">Instagram</a>
            </div>
            <div
              css={css`
                @media ${theme.devices.max.tablet} {
                  display: none
                }
              `}
            >
              <Bottom />
            </div>
          </div>
          <div
            css={css`
              margin-left: auto;
              flex-shrink: 0;
              & > a + a {
                margin-left: 20px;
              }
              @media ${theme.devices.max.tablet} {
                margin-left: 0;
              }
            `}
          >
            <AppButton
              link="https://apps.apple.com/tw/app/id1510380151"
              image={appStore}
            />
            <AppButton
              link="https://play.google.com/store/apps/details?id=com.thothtw.thoth"
              image={googlePlay}
            />
          </div>
          <div
            css={css`
              display: none;
              @media ${theme.devices.max.tablet} {
                display: block;
              }
            `}
          >
            <Bottom />
          </div>
        </div>
      </Margin>
    </footer>
  );
}